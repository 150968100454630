@mixin sm {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

a{
    text-decoration: none;
    color: black;
}
.post{
    width: 100%;
    height: 200px;
    // background-color: #ce0f0f;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    font-family: sans-serif;
    box-shadow: 0px 2px 8px #f2f2f2;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @include sm{
        height: 100px;
        margin-bottom: 10px;
    }

    .thumnailContainer{
        width: 35%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include sm{
            width: 40%;   
        }
        
        .thumnail{
            height: 90%;
            width: 90%;
            object-fit: cover;
        }
    }

    .postDes{
        width: 70%;
        height: 100%;
        // background-color: #f2f2f2;
        padding: 10px 15px 15px 15px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include sm{
            width: 60%;
            height: 100%;
            // background-color: #fff;
            // margin: 10px 0;
            box-sizing: border-box;
            padding: 5px;
        }

        .title{
            
            line-height: 30px;
            font-size: 22px;
            font-weight: bold;
            height: 30%;
            display: flex;
            align-items: center;

            @include sm{
                font-size: 0.9em;
                line-height: 15px;
                max-height: 30px;
            }

            p{
                margin: 0;
                // height: 100%;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;  
                overflow: hidden;
                text-overflow: ellipsis;

            }
        }
        .des{
            height: 40%;
            margin: 0;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-line;

            @include sm{
                height: 45px;
                font-size: 0.7em;
                line-height: 15px;
                -webkit-line-clamp: 4;
            }
        }
        .date{
            height: 10%;
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            padding-right: 40px;

            @include sm{
                padding-right: 20px;
                height: 10px;
                font-size: 0.7em;
            }
        }

    }

}