@mixin sm {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

.container{
    width: 60vw;
    margin-top: 100px;
    margin-left: 20vw;

    @include sm{
        width: 90%;
        margin-left: 5%;
    }

    .tiny{
        width: 100%;
        font-size: 20px;
        font-family: "Noto Serif","Regular","Times New Roman";
        line-height: 30px;

        img{
            max-width: 100%;
            height: auto;
        }
    }
}