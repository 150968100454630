@mixin sm {
    @media only screen and (max-width: 600px) {
        @content;
    }
}


.container{
    // background-color: rgb(210, 209, 209);
    width: 80vw;
    margin-left: 10vw;
    margin-top: 100px;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    @include sm{
        width: 90vw;
        margin-left: 5vw;
        margin-top: 60px;
    }

    .courseContainer{
        width: 68%;

        @include sm{
            width: 100%;
        }


        .intro{
            width: 100%;
            height: fit-content;
            background-color: #fff;
            box-sizing: border-box;
            padding: 10px;
            font-size: 1vw;
            border: solid black 2px;

            margin-bottom: 20px;

            span{
                font-size: 20px;
                font-weight: 600;
            }

        }

        .filter{
            width: 100%;
            // background-color: rgb(132, 132, 132);
            height: 25px;
            display: flex;
            flex-direction: row;
            
            @include sm{
                height: fit-content;
                flex-wrap: wrap;
                justify-content: space-around;
            }

            .creterion{
                cursor: pointer;
                color: #fff;
                width: 12%;
                font-size: 1vw;
                background-color: rgb(118, 118, 118);
                margin-right: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                

                @include sm{
                    height: 20px;
                    width: 18%;
                    margin-right: 0;
                    font-size: 9px;
                }
            }

            .selected{
                width: 12%;
                font-size: 1vw;
                background-color: rgb(211, 211, 211);
                margin-right: 5px;
                display: flex;
                justify-content: center;
                align-items: center;   
                box-sizing: border-box;
                border-bottom: 3px solid #000;

                @include sm{
                    height: 20px;
                    width: 18%;
                    font-size: 7px;
                    margin-right: 0;

                }
            }

            .search{
                flex: 1;
                display: flex;
                justify-content: flex-end;

                @include sm{
                    flex: none;
                    width: 100%;
                    margin-top: 10px;
                }

                .searchContainer{
                    width: 80%;
                    display: flex;
                    flex-wrap:nowrap;
                    align-items: center;
                    
                    background-color: #fff;
                    border-radius: 5px;
                    border: 2px grey solid;


                    .icon{
                        width: 20px;
                        height: 20px;
                        margin-left: 5px;
                    }
                    
                    input{
                        flex: 1;
                        border: none;
                        min-width: 0;

                        &:focus{
                            outline: none;
                        }
                    }
                }
            }
        }

        .listContainer{
            box-sizing: border-box;
            margin-top: 20px;
            width: 100%;
            height: fit-content;
            background-color: #fff;

            display: grid;
            grid-template-columns: auto auto auto auto;
            // align-content: space-between;
            justify-content: space-around;
            row-gap: 2vw;
            padding: 2vw 1vw;

            @include sm{
                display: block;
                background-color: rgba(255, 255, 255,0);
                margin-top: 0;
            }
            
            a{
                text-decoration: none;
            }


            .listItem{
                width: 12vw;
                height: 12vw;
                background-color: rgb(255, 255, 255);
                display: flex;
                flex-direction: column;
                position: relative;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                
                .courseDetail{
                    display: none;
                }

                @include sm{
                    width: 100%;
                    height: 90px;
                    background-color: #fff;
                    margin: 10px 0;
                    flex-direction: row;
                    box-sizing: border-box;
                    padding: 5px;

                    .courseDetail{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 0 5px;
                        flex: 1;

                        .title{
                            flex: 1;
                            height: 30px;
                            display: flex;
                            align-items: center;

                            span{
                                font-weight: 600;
                                font-size: 0.9em;
                                line-height: 15px;
                                max-height: 30px;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;  
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: pre-line;
                            }
                        }

                        .des{
                            max-height: 50px;
                            line-height: 15px;
                            font-size: 0.7em;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;  
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: pre-line;
                        }

                        .titleLoading{
                            flex: 1;
                            height: 30px;
                            display: flex;
                            flex-direction: column;
                            
                            .titleSke{
                                width: 100%;
                                height: 10px;
                                margin: 2px;
                            }
                        }
                        
                        .desLoading{
                            display: flex;
                            flex-direction: column;
                            height: 50px;
                            width: 100%;
                            margin-left: 2px;
                        }
                    }
                }
                
                .markIcon{
                    background-color: #515151;
                    position: absolute;
                    right: 0;
                    top: 0px;
                    color: #f0eded;
                    width: 12%;
                    height: 14%;

                    @include sm{
                        width: 6%;
                    }
                }

                img{
                    width: 100%;
                    height: 9vw;
                    object-fit: cover;

                    @include sm{
                        width: 100px;
                        height: 80px;
                    }
                }

                .imgSkeleton{
                    width: 100%;
                    height: 9vw;
                    object-fit: cover;

                    @include sm{
                        width: 100px;
                        height: 80px;
                    }
                }

                .courseTitle{
                    box-sizing: border-box;
                    padding: 0.25vw 0.5vw;
                    height: 3vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    line-height: 1.2vw;
                    font-size: 1vw;
                    font-weight: 400;

                    @include sm{
                        display: none;
                    }
                    
                    span{
                        max-width: 10vw;
                        max-height: 2.4vw;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical; 
                        overflow: hidden; 
                        word-wrap: break-word;
                        text-overflow: ellipsis; 
                        white-space: pre-line;
                        
                    }

                    .textSke{
                        width: 100%;
                        height: 10px;
                        margin: 3px;
                        border-radius: 3px;
                    }
                }
            }
        }

    }

    .widgetWrapper{
        width: 30%;

        @include sm{
            display: none;
        }
    }
    
}