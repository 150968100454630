@mixin sm {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

.limitPageContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    span{
        margin-top: 20px;
        background-color: #0213ff;
        color: white;
        padding: 15px;
        border-radius: 20px;
    }
}


.container{

    width: 80vw;
    height: fit-content;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 70px;

    @include sm {
        width: 98%;
        margin-left: 1vw;
    }
    

    .navBar {
        width: 100vw;
        position: fixed;
        left: 0;
        top: 0;
        height: 55px;
        background-color: rgb(2, 2, 2);
        z-index: 999;
    
    }

    .blurBackground{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(60, 60, 60,0.75);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
       
        .overviewWrapper{
            width: 65%;
            height: fit-content;
            position: relative;

            .overviewImage{
                width: 100%;   
            }
        }

    }

    .main{

        display: flex;
        justify-content: space-between;
        width: 100%;

        @include sm {
            flex-direction: column;
        }

        .exerciseContainer{
            width: 68%;

            @include sm {
                width: 100%;
            }

            .mobileWidgetContainer{
                display: none;

                @include sm{
                    display: block;
                    margin-top: 30px;
                }
            }

            .exercise {
                
                
                height: fit-content;
                // background-color: #D9D9D9;
                // display: flex;
                // flex-direction: column;
        
                
        
                .questionContainer{
                    box-sizing: border-box;
                    width: 100%;
                    height: fit-content;
                    border-radius: 10px 10px 0 0;
                    background-color: #D9D9D9;
                    // padding-bottom: 5px;
                    // justify-content: center;
                    // align-items: center;
                    // display: flex;
        
                    .question{
                        width: 95%;
                        height: 15%;
                        padding-left: 5%;
                        // background-color: rgb(103, 101, 101);
                        display: flex;
                        flex-direction: column;
        
                        .text{
                            height: 50%;
                            display: flex;
                            margin-top: 10px;
                            margin-bottom: 5px;
                            justify-content: flex-start ;
                            font-weight: 600;
                            font-size: 1.1em;
                        }
                        .stat{
                            height: 50%;
                            display: flex;
                            justify-content: flex-start ;
                            font-size: 1em;
                            margin-bottom: 10px;

                            .p{
                                font-weight: 600;
                                color: #043df8;
                            }
                        }
                    }
    
                    .display{
                        width: 100%;
                        height: fit-content;
                        position: relative;

                            .holder{
                                width: 100%;
                                padding-top: 55%;


                                // @include sm{
                                //     height: 22vh;
                                // }
                            }

                            .initChart{
                                width: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                            .initImg{
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                z-index: 2;
                            }
                            img{
                                
                                width: 100%;
                            
                                // object-fit: cover;
                            }

                            .mainImg{
                                z-index: 1;
                            }

                            .mainImgLoading{
                                z-index: 1;
                                display: none;
                            }
            
                            video{

                                width: 100%;
                                z-index: 3;
                                position: absolute;
                                top: 0;
                                left: 0;
                                
                                
                                // object-fit: cover;
                            }
    
                            .checkIconFalse{
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                opacity: 0.6;
                                border: solid 5px rgb(204, 0, 0, 1);
                                box-sizing: border-box;
                                color: red;
                                z-index: 99;
                            }
                            .checkIconTrue{
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                opacity: 0.6;
                                border: solid 5px green;
                                box-sizing: border-box;
                                color: green;
                                z-index: 99;
                            }
                    }
        
                }
        
        
                .showContainer{
        
                    height: 50px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    border-radius: 0 0 10px 10px;
                    background-color: #F0E8E8;
    
    
                    .answer{
                        color: white;
                        border-radius: 10px;
                        border: solid 1px #3c3c3c;
                        width: 90px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
    
    
                        background-color: #005ae0;
                        cursor: pointer;      
                        
                        span{
                            line-height: 1em;
                            font-size: 1em;
                        }
                    }
    
                    .answerDisabled{
                        color: white;
                        border-radius: 10px;
                        border: solid 1px #3c3c3c;
                        width: 90px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
    
    
                        background-color: #3c3c3c;
                        pointer-events: none;   
                        
                        span{
                            line-height: 1em;
                            font-size: 1em;
                        }                    
                    }
    
                    .answerPicked{
                        color: white;
                        border-radius: 10px;
                        border: solid 1px #888888;
                        width: 90px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
    
    
                        background-color: #27b0ff;
                        cursor: pointer;      
                        
                        span{
                            line-height: 1em;
                            font-size: 1em;
                        }                    
                    }
        
                    .btnShow{
                        
                        width: 50px;
                        height: 30px;
                        background-color: rgb(17, 5, 255);
                        cursor: pointer;
                    }
                }
        
            }
            
            .controller{
                margin-top: 15px;
                margin-bottom: 15px;
                width: 100  ;
                height: 40px;
                // background-color: rgb(77, 68, 141);
                color: aliceblue;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include sm {
                    font-size: 10px;
                    height: 30px;
                }
        
                .reset{
                    color: white;
                    width: 25%;
                    height: 80%;
                    border-radius: 5px;
                    background-color: #2678F3;
                    border: none;
    
                    display: -webkit-box;
                    height: 80%;
                    // font-size: 1em;
                    font-weight: normal;
                    line-height: 80%;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @include sm{
                        height: 100%;
                        width: 30%;
                        font-size: 10px;
                        line-height: 12px;
                    }
                }
    
                .next{
                    color: white;
                    width: 25%;
                    height: 80%;
                    border-radius: 5px;
                    background-color: #2678F3;
                    border: none;
                    cursor: pointer;
                    line-height: 80%;
                    
                    @include sm{
                        height: 100%;
                        width: 30%;
                        font-size: 10px;
                        line-height: 12px;
                    }
                }

                .nextDisabled{
                    color: white;
                    width: 25%;
                    height: 80%;
                    border-radius: 5px;
                    background-color: #484949;
                    border: none;  
                    line-height: 80%;
                    
                    @include sm{
                        height: 100%;
                        width: 30%;
                        font-size: 10px;
                        line-height: 12px;
                    } 
                }

                .disabled{
                    color: rgb(200, 200, 200);
                    width: 25%;
                    height: 80%;
                    border-radius: 5px;
                    background-color: #434343;
                    border: none;
                    cursor: pointer;

                    @include sm{
                        height: 100%;
                        width: 30%;
                        line-height: 15px;
                    }
                }
            }

            .showAllEx{
                // border-bottom: 1px solid black;
                text-decoration: underline;
            }
        
            .explaination{
                width: 100%;
                height: fit-content;
                margin-top: 15px;
                margin-bottom: 20px;
                background-color: #ffffff;
        
                span{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 40px;
                    font-size: 2vw;
                    font-weight: bold;
                    background-color: #d1d3d3;
                }

                .tiny{
                    width: 100%;
                    height: 300px;
                    // overflow-y: scroll;
                    height: fit-content;
                    font-size: 18px;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    font-family: "Noto Serif","Regular","Times New Roman";
                    line-height: 20px;
            
                    img{
                        max-width: 100%;
                        height: auto;
                    }
                }

        
                .course{
                    width: 100%;
                    height: 180px;
                    background-color: #DE7E7E;
                    color: white;
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    

                    img{
                        width: 33%;
                        object-fit: cover;
                    }

                    .courseDetail{
                        height: 180px;
                        display: flex;
                        flex-direction: column;
                        padding: 10px 10px;
                        box-sizing: border-box;


                        @include sm{
                            padding: 0 5px;
                            flex: 1;
                        }

                        .courseTitleWrapper{
                            flex: 1;
                            max-height: 80px;
                            display: flex;
                            align-items: center;

                            @include sm{
                                flex: 1;
                                height: 30px;
                                display: flex;
                                align-items: center;
                            }

                            .courseTitle{
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 40px;
                                max-height: 80px;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;  
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: pre-line;
                                background: none;
                            }
                        }

                        .des{
                            max-height: 100px;
                            line-height: 25px;
                            font-size: 16px;
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;  
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: pre-line;
                        }
                    }
                }
            }
        }

        .widgetContainer{
            width: 30%;

            @include sm{
                display: none;
                // width: 100%;
            }
            .widget{
                // position: fixed;
                // width: 24vw;
                // height: 50vh;
                // top: 70px;
                // right: 10vw;
                width: 100%;
            }

        }

    }


}


