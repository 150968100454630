@mixin sm {
    @media only screen and (max-width: 600px) {
      @content;
    }
}

.container{
    // width: 100vw;
    height: 100vh;
    // max-width: 100%;
    width: 100%;
    // height: 100%;
    max-height: 100%;
    position: relative;
    overflow: hidden;

    .pcImage{
        width: 100%;
        height: 100%;
    }

    .mobileImage{
        display: none;
    }
    
    @include sm {
        .pcImage{
            display: none;
        }
        .mobileImage{
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
        }
    }


    .text{
        color: white;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .span1{
            font-size: 50px;

            @include sm {
                font-size: 28px;
            }
        }
        .span2{
            font-size: 70px;
            font-weight: bold;
            margin: 10px;
            margin-bottom: 10px;
            color: #0066ED;
        }
        .span3{
            font-size: 40px;

            @include sm{
                width: 95%;
                font-size: 25px;
                text-align: center;
                margin-top: 10px;
                color: rgb(255, 255, 255);
            }
        }

        .startButton{
            width: 220px;
            height: 60px;
            margin-top: 30px;
            background-color: #0008ff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            
            color: white;
            font-weight: 700;
            font-size: 20px;
            line-height: 50%;
        }
    }

}