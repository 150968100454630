@mixin sm {
    @media only screen and (max-width: 600px) {
        @content;
    }
}
.mobileNavBar {
    display: none;
}

.mobileHomeNavBar {
    display: none;
}

.homeNavBar {
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    height: 50px;
    // background-color: rgb(208, 208, 208);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-weight: 500;
    font-family: sans-serif;
    font-size: 1.2vw;
    z-index: 99999;

    @include sm {
        font-size: 25px;
        display: none;
    }

    .notificationBox {
        position: fixed;
        background-color: white;
        top: 50px;
        right: 50px;
        height: 610px;
        display: flex;
        flex-direction: column;
        width: 360px;
        border-radius: 15px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        .title {
            font-weight: bold;
            width: 100%;
            height: fit-content;
            font-size: 30px;
            box-sizing: border-box;
            padding: 10px 20px;
            border-bottom: 0.1px solid #cfcfcf;
        }

        .notificationContainer {
            // height: 540px;
            flex: 1;
            margin-bottom: 15px;
            position: relative;
            overflow-y: scroll;

            .loadingWrapper {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                .loading {
                    width: 40px;
                    height: 40px;
                }
            }

            .notifWrapper {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 10px 0;
                padding: 10px;
                box-sizing: border-box;
                cursor: pointer;

                .notifAvatar {
                    width: 55px;
                    height: 55px;
                    // margin-right: 10px;
                    // margin-left: 10px;
                    font-size: 26px;
                }

                .notifBody {
                    width: 255px;
                    min-height: 55px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .unreadNotifContent {
                        position: relative;
                        font-size: 17px;
                        max-height: 45px;

                        span {
                            font-weight: bold;
                        }

                        .dot {
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            background-color: #002aff;
                            border-radius: 50%;
                            right: -10px;
                            top: 50%;
                            transform: translate(0, 50%);
                        }
                    }

                    .unreadTimePassed {
                        margin-top: 5px;
                        font-size: 12px;
                        color: #002aff;
                    }

                    .notifContent {
                        font-size: 17px;
                        max-height: 45px;
                        span {
                            font-weight: bold;
                        }
                    }

                    .timePassed {
                        margin-top: 5px;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .logo {
        width: 180px;
        height: 100%;
        // background-color: rgb(255, 0, 0);
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-left: 30px;
        font-size: 25px;
        font-weight: 700;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .nav {
        width: 40%;
        height: 50px;
        padding: 0;
        // background-color: rgb(92, 1, 1);
        display: flex;

        .navItem {
            cursor: pointer;
            width: 33%;
            font-size: 1.1vw;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: rgb(255, 255, 255);

            span {
            }
        }

        .linkActive {
            pointer-events: none;
            width: 33%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            // background-color: rgba(142, 142, 142,0.6);
            color: rgb(11, 53, 238);
            font-weight: 600;
            font-size: 1.3vw;
            // border-bottom: rgb(44, 76, 253) 5px solid;
        }
    }

    .auth {
        width: 22%;
        display: flex;

        .log {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;



            .a {
                width: 80%;
                height: 70%;
                border: none;
                color: white;
                border-radius: 30px;
                background-color: #0151c9;
                
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                font-weight: 600;
                font-size: 1vw;
                box-sizing: border-box;
                padding-top: 2px;
            }
        }
    }

    .profile {
        width: 18%;
        display: flex;
        justify-content: flex-end;

        .avatarContainer {
            position: relative;
            width: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .avatar {
                width: 40px;
                height: 40px;
                font-size: 20px;
            }

            .avatarContainerOverlay{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
        .dropbox {
            position: absolute;
            top: 55px;
            width: 150px;
            height: fit-content;
            background-color: #ffffff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;

            .item1 {
                padding-right: 10%;
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                font-size: 16px;
                // border-bottom: 2px solid #000;
                cursor: pointer;
            }

            .item2 {
                padding-right: 10%;
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                font-size: 16px;
                background-color: #e3e3e3;
                // border-bottom: 2px solid #000;
                cursor: pointer;
                border-radius: 0 0 10px 10px;
            }
        }
        .notification {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5vw;
            cursor: pointer;
            color: #ffffff;
            position: relative;

            .iconWrapper {
                position: relative;

                .newNotifCount {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: white;
                    background-color: #ff0101;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 15px;
                    height: 15px;

                    .notifCountWrapper {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        font-size: 9px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .notifOverlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}





.navBar {
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    height: 50px;
    background-color: rgb(208, 208, 208);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-weight: 500;
    font-family: sans-serif;
    font-size: 1.2vw;
    z-index: 99999;

    @include sm {
        font-size: 25px;
        display: none;
    }

    .notificationBox {
        position: fixed;
        background-color: white;
        top: 50px;
        right: 50px;
        width: 360px;
        height: 610px;
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        @include sm{
            top: 40px;
            left: 0;
            width: 200px;
            height: 200px;
        }

        .title {
            font-weight: bold;
            width: 100%;
            height: fit-content;
            font-size: 30px;
            box-sizing: border-box;
            padding: 10px 20px;
            border-bottom: 0.1px solid #cfcfcf;
        }

        .notificationContainer {
            // height: 540px;
            flex:1;
            width: 100%;
            margin-bottom: 15px;
            position: relative;
            overflow-y: scroll;

            .loadingWrapper {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                .loading {
                    width: 40px;
                    height: 40px;
                }
            }

            .notifWrapper {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 10px 0;
                padding: 10px;
                box-sizing: border-box;
                cursor: pointer;

                .notifAvatar {
                    width: 55px;
                    height: 55px;
                    // margin-right: 10px;
                    // margin-left: 10px;
                    font-size: 26px;
                }

                .notifBody {
                    width: 255px;
                    min-height: 55px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .unreadNotifContent {
                        position: relative;
                        font-size: 17px;
                        max-height: 45px;

                        span {
                            font-weight: bold;
                        }

                        .dot {
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            background-color: #002aff;
                            border-radius: 50%;
                            right: -10px;
                            top: 50%;
                            transform: translate(0, 50%);
                        }
                    }

                    .unreadTimePassed {
                        margin-top: 5px;
                        font-size: 12px;
                        color: #002aff;
                    }

                    .notifContent {
                        font-size: 17px;
                        max-height: 45px;
                        span {
                            font-weight: bold;
                        }
                    }

                    .timePassed {
                        margin-top: 5px;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .logo {
        width: 170px;
        height: 100%;
        // background-color: rgb(255, 0, 0);
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-left: 30px;
        font-size: 25px;
        font-weight: 700;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .nav {
        width: 40%;
        height: 50px;
        padding: 0;
        // background-color: rgb(92, 1, 1);
        display: flex;

        .navItem {
            cursor: pointer;
            width: 33%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: rgb(85, 85, 85);
            font-size: 1.1vw;
            font-weight: 600;
            span {
            }
        }

        .linkActive {
            pointer-events: none;
            width: 33%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            background-color: rgb(187, 187, 187);
            color: rgb(23, 73, 252);
            font-weight: 600;
            font-size: 1.3vw;
            border-bottom: rgb(44, 76, 253) 5px solid;
        }
    }

    .auth {
        width: 22%;
        display: flex;

        .log {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            button {
                width: 80%;
                height: 60%;
                border: none;
                color: white;
                border-radius: 30px;
                background-color: #2678f3;
            }

            .a {
                width: 80%;
                height: 70%;
                border: none;
                color: white;
                border-radius: 30px;
                background-color: #015be2;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                font-size: 1vw;
                font-weight: 600;
                padding-top: 2px;
                box-sizing: border-box;
            }
        }
    }

    .profile {
        width: 18%;
        display: flex;
        justify-content: flex-end;

        .avatarContainer {
            position: relative;
            width: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .avatar {
                width: 40px;
                height: 40px;
                font-size: 20px;
            }

            .avatarContainerOverlay{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
        .dropbox {
            position: absolute;
            top: 55px;
            width: 150px;
            height: fit-content;
            background-color: #ffffff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;

            .item1 {
                padding-right: 10%;
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                font-size: 16px;
                // border-bottom: 2px solid #000;
                cursor: pointer;
            }

            .item2 {
                padding-right: 10%;
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                font-size: 16px;
                background-color: #e3e3e3;
                // border-bottom: 2px solid #000;
                cursor: pointer;
                border-radius: 0 0 10px 10px;
            }
        }
        .notification {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5vw;
            cursor: pointer;
            position: relative;

            .iconWrapper {
                position: relative;

                .newNotifCount {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: white;
                    background-color: #ff0101;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 15px;
                    height: 15px;

                    .notifCountWrapper {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        font-size: 9px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .notifOverlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}



@mixin inner-mobile-nav {

    .mobileNav{
        position: fixed;
        top: 0;
        right: 0;
        width: 80vw;
        height: 100vh;
        background-color: rgba(27, 26, 26, 0.99);
        z-index: 9999;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        // padding: 20px;
        padding-top: 30px;
        align-items: center;

        .navAvatar{
            width: 80px;
            height: 80px;
            font-size: 40px;
            margin-bottom: 10px;
        }

        .userName{
            font-size: 20px;
            width: 100%;
            color: white;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .nav{
            display: flex;
            flex-direction: column;
            color: white;
            width: 100%;
            z-index: 9999;
            // height: 500px;

            .linkActive{
                box-sizing: border-box;
                padding-left: 20px;
                color: white;
                text-decoration: none;
                height: 100px;
                font-size: 24px;
                background-color: #5a5a5a;
                font-weight: bold;
                display: flex;
                align-items: center;
                border-bottom: 2px solid white;

                
                .navTitle{
                    margin-left: 15px;
                }
            }
            .navItem{
                box-sizing: border-box;
                padding-left: 20px;
                color: white;
                text-decoration: none;
                height: 80px;
                font-size: 20px;
                font-weight: 600;
                display: flex;
                align-items: center;

                .navTitle{
                    margin-left: 15px;
                }
            }
        }
    }
    .menu {
        width: 25%;
        height: 40px;
        display: flex;
        justify-content: center;

        .homeMenuIcon {
            width: 40px;
            height: 100%;
            color: white;
        }

        .menuIcon {
            width: 40px;
            height: 100%;
            color: rgb(78, 78, 78);
        }
    }

    .mobileLogo {
        height: 40px;
        width: 100px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    button {
        height: 25px;
        width: 25%;
        margin-right: 10px;
        border-radius: 10px;
        color: white;
        font-weight: bold;
        background-color: #4542f7;
        border: none;
        cursor: pointer;
    }

    .profile{
        width: 25%;
        display: flex;
        justify-content: flex-end;

        .avatarContainer {
            position: relative;
            flex-grow: 1;
            width: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .avatar {
                width: 30px;
                height: 30px;
                font-size: 16px;
            }

            .avatarContainerOverlayMobile{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
        .mobileDropbox {
            position: absolute;
            top: 40px;
            width: 120px;
            height: fit-content;
            background-color: #ffffff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;

            .item1 {
                padding-right: 10%;
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                font-size: 16px;
                // border-bottom: 2px solid #000;
                cursor: pointer;
            }

            .item2 {
                padding-right: 10%;
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                font-size: 16px;
                background-color: #e3e3e3;
                // border-bottom: 2px solid #000;
                cursor: pointer;
                border-radius: 0 0 10px 10px;
            }
        }
    }

    .mobileNotificationBox {
        position: fixed;
        background-color: white;
        top: 40px;
        width: 100vw;
        height: 100vh;
        border-radius: 15px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        .title {
            font-weight: bold;
            width: 100%;
            height: fit-content;
            font-size: 30px;
            box-sizing: border-box;
            padding: 10px 20px;
            border-bottom: 0.1px solid #cfcfcf;
        }

        .notificationContainer {
            height: 78vh;
            position: relative;
            overflow-y: scroll;

            .loadingWrapper {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                .loading {
                    width: 40px;
                    height: 40px;
                }
            }

            .notifWrapper {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 10px 0;
                padding: 10px;
                box-sizing: border-box;
                cursor: pointer;

                .notifAvatar {
                    width: 55px;
                    height: 55px;
                    // margin-right: 10px;
                    // margin-left: 10px;
                    font-size: 26px;
                }

                .notifBody {
                    flex: 1;
                    box-sizing: border-box;
                    padding-left: 15px;
                    padding-right: 6px;
                    min-height: 55px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .unreadNotifContent {
                        position: relative;
                        font-size: 17px;
                        max-height: 45px;

                        span {
                            font-weight: bold;
                        }

                        .dot {
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            background-color: #002aff;
                            border-radius: 50%;
                            right: -10px;
                            top: 50%;
                            transform: translate(0, 50%);
                        }
                    }

                    .unreadTimePassed {
                        margin-top: 5px;
                        font-size: 12px;
                        color: #002aff;
                    }

                    .notifContent {
                        font-size: 17px;
                        max-height: 45px;
                        span {
                            font-weight: bold;
                        }
                    }

                    .timePassed {
                        margin-top: 5px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@include sm {
    .mobileHomeNavBar {
        width: 100%;
        display: block;
        height: 40px;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 99;

        @include inner-mobile-nav();

        .notification {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25%;
            cursor: pointer;
            position: relative;


            .iconWrapper {
                position: relative;
                color: #ffffff;

                .newNotifCount {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: white;
                    background-color: #ff0101;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 15px;
                    height: 15px;

                    .notifCountWrapper {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        font-size: 9px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .notifOverlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .mobileNavBar {
        width: 100%;
        display: block;
        height: 40px;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #c6c6c6;
        z-index: 9999;

        @include inner-mobile-nav();

        .notification {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25%;
            cursor: pointer;
            position: relative;


            .iconWrapper {
                position: relative;

                .newNotifCount {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: white;
                    background-color: #ff0101;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 15px;
                    height: 15px;

                    .notifCountWrapper {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        font-size: 9px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .notifOverlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
