/* .App {
  text-align: center;
  background-color: rgb(255, 255, 255);
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.skeleton{
  opacity: 0.7;
  background-color: aqua;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgb(168, 168, 168);
  }

  100% {
    background-color: rgb(235, 235, 235);
  }
}