
@mixin sm {
    @media only screen and (max-width: 600px) {
        @content;
    }
}


.widget{
    width: 100%;
    margin-left: 5%;
    box-sizing: border-box;
    height: 100vh;


    @include sm {
        margin-left: 0;
        height: fit-content;
    }

    .status{
        width: 100%;
        height: fit-content;
        background-color: #fff;
        padding-bottom: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;


        .text{
           
            width: 100%;
            height: 3vw;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 5px;
            font-size: 1.3vw;
            font-weight: 600;

            @include sm{
                height: fit-content;
                font-size: 18px;
                margin-top: 10px;
            }
        }

        .record{
            height: 10vw;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            @include sm{
                height: 100px;
                margin-top: 20px;

                span{
                    font-size: 14px;
                }
            }

            .practiceRecord{
                position: relative;
                width: 90%;
                height: 30%;
                background-color: #e6e4e4;  
                border-radius: 20px;  
                margin-bottom: 0.2vw;        

                .userPractice{
                    position: absolute;
                    height: 100%;
                    background-color: #3966AA;
                    border-radius: 20px;
                }

                .recordTitle{
                    position: absolute;
                    top:0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 500;
                    font-size: 1.2vw;
                    
                    span{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        line-height: 100%;
                    }
                }
                .percentage{
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translate(0, -50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.2vw;
                    box-sizing: border-box;
                    padding-bottom: 1px;
                    // line-height: 50%;

                    @include sm{
                        font-size: 14px;
                    }
                }
            }

            .practiceRateRecord{
                position: relative;
                width: 90%;
                height: 30%;
                background-color: #ebe6e6;  
                border-radius: 20px;  
                margin-bottom: 0.2vw;        

                .userPractice{
                    position: absolute;
                    height: 100%;
                    background-color: #3966AA;
                    border-radius: 20px;
                }

                .recordTitle{
                    position: absolute;
                    top:0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 500;
                    font-size: 1.2vw;
                    
                    span{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        line-height: 100%;
                    }
                }
                .percentage{
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translate(0, -50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.2vw;
                    box-sizing: border-box;
                    padding-bottom: 1px;
                    // line-height: 50%;

                    @include sm{
                        font-size: 14px;
                    }
                }
            }

            .courseRecord{
                position: relative;
                width: 90%;
                height: 30%;
                background-color: #F5F5F5;  
                border-radius: 20px;   
                margin-top: 1vw;

                .userPractice{
                    position: absolute;
                    width: 80%;
                    height: 100%;
                    background-color: #68A0F5;
                    border-radius: 20px;
                }

                .recordTitle{
                    position: absolute;
                    top:0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 500;
                    font-size: 1.2vw;
                    
                    span{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        line-height: 100%;
                    }
                }
                .percentage{
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translate(0, -50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 100%;
                    font-size: 1.2vw;

                    @include sm{
                        font-size: 14px;
                    }
                }
            }

        }
        .suggestion{
            text-align: center;
            padding: 15px 5%;
            font-size: 1vw;

            @include sm{
                font-size: 14px;
            }
        }
    }

    .fireAnt{
        position: relative;

        // .block{
        //     position: absolute;
        //     width: 100%;
        //     height: 13%;
        //     background-color: #ffffff;
        //     bottom: 0;
        //     border-top: 1px solid #000;
        // }
    }

    .contact{
        margin-top: 20px;
        width: 100%;
        height: fit-content;
        background-color: #fff;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;


        .text{
           
            width: 100%;
            height: 3vw;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 5px;
            font-size: 1.3vw;
            font-weight: 600;

            @include sm{
                font-size: 18px;
                margin-bottom: 15px;
                margin-top: 15px;
            }
        }

        .contactInfo{
            width: 90%;
            height: fit-content;
            background-color: #E8EBF6;
            box-sizing: border-box;
            padding: 17px 25px;
            display: flex;      
            justify-content: center;
            border-radius: 20px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            cursor: pointer;
            margin-bottom: 20px;
            

            a{
                font-size: 15px;
                text-align: center;
                font-weight: 700;
            }
        }
    }

    .greeting{
        margin-top: 20px;
        width: 100%;
        height: fit-content;
        background-color: #fff;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;  
        margin-bottom: 30px;

        .text{
           
            width: 100%;
            height: 3vw;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 5px;
            font-size: 1.2vw;
            font-weight: 600;
            text-align: center;

            @include sm {
                font-size: 16px;
                margin: 10px 0;
            }
        }

        .newUsers{
            width: 90%;
            .newUser{
                display: flex;
                // justify-content: center;
                align-items: center;
                margin: 10px;
                .avatar{
                    width: 70px;
                    height: 70px;
                    font-size: 30px;

                    @include sm{
                        width: 50px;
                        height: 50px;
                    }

                    .avatarSkeleton{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }

                .newUserName{
                    margin-left: 20px;
                    font-size: 18px;
                    font-weight: bold;
                }

                .newUserNameSkeleton{
                    margin-left: 20px;
                    flex: 1;
                    height: 25px;
                }
            }
        }
    }

}