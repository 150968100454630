.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .title {
    font-size: 4rem;
    color: #ff0000;
  }
  
  .message {
    font-size: 1.5rem;
    margin: 1rem;
  }
  
  .link {
    font-size: 1.2rem;
    text-decoration: none;
    color: #0000ff;
  }
  
  .link:hover {
    text-decoration: underline;
  }