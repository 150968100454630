@mixin sm {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

.login {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
	

    .container {
        width: 35%;
		position: relative;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
		padding-bottom: 80px;
		box-sizing: border-box;
		border-radius: 10px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		

		@include sm{
			width: 90%;
			margin-bottom: 40px;
		}

        .logo {
            width: 350px;
			margin-top: 30px;

			@include sm{
				width: 70%;
			}

			img{
				width: 100%;
			}
        }

		.inputWrapper{
			width: 80%;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			border-bottom: 1px solid black;

			input {
				border: 0;
				width: 90%;
				
				font-size: 16px;
				margin: 10px 0;
	
				&:focus {
					outline: 0;
					content:""
					
				}
			}

		}

        .loginButtonDisabled {
			width: 70%;
			height: 50px;
			cursor: default;	
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #b8b8b8;
			color: rgb(109, 109, 109);
			border-radius: 10px;
			font-size: 20px;
			margin-top: 50px;
			margin-bottom: 5px;
			
        }

		.loginButton{
			width: 70%;
			height: 50px;
			border: none;
			cursor: pointer;	
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #2B4BBC;
			color: #fff;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 10px;
			font-size: 20px;	
			margin-top: 50px;	
			margin-bottom: 5px;
		}

		.forgot{
			font-size: 14px;
			color: #686868;
			cursor: pointer;
			margin-bottom: 20px;
		}

		.loading{
			position: absolute;
			bottom: 15px;

		}

		.error{
			color: red;
			margin-top: 10px;
		}
    }
}
