.loading-page {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
  }
  
  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .spinner {
    width: 80px;
    height: 80px;
    position: relative;
  }
  
  .bar {
    width: 20px;
    height: 20px;
    background-color: #013cff;
    position: absolute;
    animation: bar-animation 1.5s ease-in-out infinite;
  
    &:nth-child(1) {
      top: 0;
      left: 0;
      animation-delay: -0.75s;
      transform-origin: bottom left;
      transform: rotate(45deg);
    }
  
    &:nth-child(2) {
      top: 0;
      right: 0;
      animation-delay: -0.5s;
      transform-origin: bottom right;
      transform: rotate(-45deg);
    }
  
    &:nth-child(3) {
      bottom: 0;
      right: 0;
      animation-delay: -0.25s;
      transform-origin: top right;
      transform: rotate(45deg);
    }
  
    &:nth-child(4) {
      bottom: 0;
      left: 0;
      animation-delay: -0s;
      transform-origin: top left;
      transform: rotate(-45deg);
    }
  }
  
  @keyframes bar-animation {
    from {
      height: 20px;
      opacity: 1;
    }
    
    to {
      height: 40px;
      opacity: 0.5;
    }
  }
  
  h2 {
    margin-top: 20px;
  }
  
  .content {
    width: 80%;
    max-width: 600px;
  }
  
  h1 {
   font-family: Arial, sans-serif; 
  }
  
  p {
   font-family: Verdana, sans-serif; 
  }