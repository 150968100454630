.container{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(121, 121, 121,0.5);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal{
        width: 300px;
        height: 200px;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 15px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .title{
            font-size: 30px;
            font-weight: 500;
            // margin-bottom: 20px;
        }

        .warning{
            font-size: 17px;
        }

        .action{
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;

            span{
                margin-left: 20px;
                margin-right: 10px;
                font-size: 18px;
                font-weight: 500;
                cursor: pointer;
            }

            .deleteBtn{
                color: red;
            }

            .cancelBtn{
                color: blue;
            }

            .deleteIconContainer{
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                img{
                    width: 24px;
                    height: 24px;
                }   
            }
        }
    }
}