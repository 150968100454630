@mixin sm {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

.register{
  	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.container{
		width: 40%;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 20px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		position: relative;

		@include sm{
			width: 90%;
		}

		.logo{
			margin-top: 20px;
			width: 350px;
			
			@include sm{
				width: 80%;
				margin-top: 40px;
			}
			img{
				width: 100%;
				// height: 150px;
			}
		}

		.inputWrapper{
			width: 100%;
			height: 75px;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			

			input {
				border: 0;
				width: 100%;
				padding: 0 3%;
				
				font-size: 16px;
				margin: 10px 0;
	
				&:focus {
					outline: 0;
					content:""
					
				}
			}

			.passwordInput{
				position: relative;
				width: 80%;
				border-bottom: 1px solid black;


				.passwordLengthError{
					position: absolute;
					bottom: -25px;
					left: 0;
					color: red;
				}

			}

			.phoneNumberInput{
				position: relative;
				width: 80%;
				border-bottom: 1px solid black;

				.existedError{
					width: 100%;
					position: absolute;
					bottom: -25px;
					left: 0;
					color: red;
				}

			}

			.emailImput{
				position: relative;
				width: 80%;
				border-bottom: 1px solid black;

				.emailError{
					width: 100%;
					position: absolute;
					bottom: -25px;
					left: 0;
					color: red;
				}

				.existedError{
					width: 100%;
					position: absolute;
					top:-100;
					left: 0;
					color: red;
				}
			}
		}

		.nameInputContainer{
			width: 80%;
			height: 75px;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			
			.nameInputWrapper{
				border-bottom: 1px solid black;
				width: 45%;
				display: flex;
				justify-content: center;
				align-items: center;

	
				input{
					border: 0;
					width: 88%;
					
					font-size: 16px;
					margin: 10px 0;
					&:focus {
						outline: 0;
						content:""
						
					}
				}
			}
		}

		.emailError{
			width: 80%;
			color: red;
		}

		.existedError{
			width: 80%;
			color: red;
		}

		.registerButtonDisabled{
			margin:50px 0;
			width: 50%;
			height: 60px;
			border: 0;
			background-color: #c7c7c7;
			font-size: 20px;
			font-weight: bold;
			color: rgb(104, 104, 104);
			border-radius: 20px;
		}

		.registerButton{
			margin: 50px 0;
			width: 50%;
			height: 60px;
			border: 0;
			background-color: #2B4BBC;
			font-size: 20px;
			font-weight: bold;
			color: rgb(255, 255, 255);
			border-radius: 20px;
			cursor: pointer;
		}

		.loading{
			position: absolute;

			width: 30px;
			height: 30px;
			bottom: 10px;
		}
	}

	.verifyContainer{
		width: 40%;
		
		background-color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 20px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		position: relative;

		img{
			margin-top: 30px;
		}

		p{
			width: 90%;
			margin: 30px;
			text-align: center;

			span{
				font-size: 1.1em;
				font-weight: bold;
			}
		}

		input{
			width: 60%;
			height: 50px;
			font-size: 1.8em;
			text-align: center;
		}

		.verifyButton{
			margin: 50px 0;
			width: 50%;
			height: 60px;
			border: 0;
			background-color: #2B4BBC;
			font-size: 20px;
			font-weight: bold;
			color: rgb(255, 255, 255);
			border-radius: 20px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.loading{
			position: absolute;
			width: 30px;
			height: 30px;
			bottom: 10px;
		}

		.wrongOtp{
			color: red;
			position: absolute;
			bottom: 10px;
		}
	}

}