@mixin sm {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

.container{
    width: 80vw;
    margin-left: 10vw;
    margin-top: 100px;
    display: flex;
    flex-direction: row;

    @include sm{
        margin-top: 60px;
        width: 90%;
        margin-left: 5vw;
    }

    .postContainer{
        width: 70%;

        @include sm{
            width: 100%;
        }

        .filter{
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            justify-content: flex-end;

            .filterIconContainer{
                width: 15vw;
                border: 2px solid rgb(170, 170, 170);

                box-sizing: border-box;
                padding: 0 10px;
                cursor: pointer;
                position: relative;

                @include sm {
                    width: 100%;
                    border: none;
                    border-bottom: 3px solid rgb(66, 66, 66);
                }

                .creWrapper{
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .overlay{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                    }
                }

                .creterion{
                    position: absolute;
                    width: 100%;
                    left: 0;
                    top: 110%;
                    height: fit-content;
                    background-color: #fff;
                    box-sizing: border-box;
                    padding: 10px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                    .creItem{
                        margin: 10px;
                    
                    }
                }
            }
        }
    }              

    .widgetWrapper{
        width: 30%;
        height: fit-content;

        @include sm{
            display: none;
        }
    }
}