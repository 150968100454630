@mixin sm {
    @media only screen and (max-width: 600px) {
        @content;
    }
}


.container{
    width: 80vw;
    margin-left: 10vw;
    margin-top: 100px;
    margin-bottom: 40px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 30px;
    background-color: #fff;

    @include sm{
        width: 90%;
        margin-left: 5%;
        margin-top: 70px;
        padding: 10px;
    }

    .avatarSetting{
        margin: 40px 0;
        padding-bottom: 20px;
        border-bottom: 2px solid #000;

        .avatarContainer{
            
            display: flex;
            justify-content: center;
            align-items: center;

            .avatar{
                font-size: 100px;
                width: 200px;
                height: 200px;

                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
    
        }

        .avatarActions{
            margin: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;

            label{
                width: 120px;
                height: 30px;
                cursor: pointer;
                background-color: #cccccc;
                margin-right: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;

                input[type="file"] {
                    display: none;
                }
            }

            span{
                margin: 5px;
                width: 120px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #3249fc;
                color: white;
                border-radius: 10px;
            }
        }

        .uploading{
            width: 100%;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: 15px;
                height: 15px;
                margin-right: 10px;
            }

            span{

            }
        }
    }

    .info{
        width: 70%;

        @include sm{
            width: 100%;
        }
        .name{
            margin-bottom: 40px;
            .title{
                font-size: 18px;
                font-weight: bold;
                margin: 10px 0;
            }

            h3{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
            }

            .changeName{
                display: flex;
                margin-top: 20px;

                .nameInput{
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    span{
                       
                        font-size: 18px;
                        font-weight: 600;
                        display: flex;
                        width: 90%;
                        box-sizing: border-box;
                        margin-bottom: 10px;
                    }

                    input{
                        width: 90%;
                        height: 40px;
                        box-sizing: border-box;
                        border: none;
                        background-color: #d9d9d9;
                        padding-left: 10px;
                        font-size: 18px;
                        border-radius: 10px;

                        &:focus{
                            outline: none;
                        }
                    }
                }
            }
        }
    }
    .confirm{
        width: 200px;
        height: 50px;
        background-color: #001eff;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        border-radius: 20px;
        cursor: pointer;
    }
}