@mixin sm {
    @media only screen and (max-width: 600px) {
      @content;
    }
}

.container{
    width: 80vw;
    margin-left: 10vw;
    margin-top: 100px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;

    @include sm{
        width: 96%;
        margin-left: 2vw;
        margin-top: 60px;
    }

    .exerciseContainer{
        width: 68%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;

        @include sm{
            width: 100%;
        }

        .intro{
            width: 100%;
            height: fit-content;
            background-color: #fff;
            box-sizing: border-box;
            padding: 10px;
            font-size: 1vw;
            border: solid black 1px;

            margin-bottom: 20px;

            @include sm{
                margin-bottom: 0;
            }

            h2{
                margin: 0;
                margin-bottom: 10px;

                @include  sm{
                    font-size: 18px;
                }
            }
            
            span{
                
                @include sm{
                    font-size: 10px;
                }
            }
            
        }
        
        .listWrapper{

            height: 55vw;
            background-color: #fff;
            margin-top: 20px;

            @include sm{
                height: 80vw;
            }

            .listContainer{
                box-sizing: border-box;
                min-width: 50vw;
                width: 100%;
                height: fit-content;
                background-color: #fff;
                display: grid;
                // grid-template-columns: auto auto auto;
                display: flex;
                flex-wrap: wrap;
                // align-content: space-between;
                row-gap: 2vw;
                padding: 2.5vw 2vw;
                @include sm{
                    padding: 3vw 2vw;
                }
    
                
                a{
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
    
    
                .listItem{
                    width: 15vw;
                    height: 13vw;
                    background-color: rgb(221, 221, 221);
                    display: flex;
                    flex-direction: column;
                    margin: 0.9vw;
                    // margin: 0 0.8vw;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    
                    @include sm{
                        width: 28.7vw;
                        height: 23vw;
                    }
    
                    img{
                        width: 100%;
                        height: 10vw;
                        object-fit: cover;
    
                        @include sm{
                            height: 16vw;
                        }
                    }
                    
                    .imgSkeleton{
                        width: 100%;
                        height: 10vw;
                        object-fit: cover;
    
                        @include sm{
                            height: 16vw;
                        }
                    }

                    .courseTitle{
                        box-sizing: border-box;
                        padding: 0.25vw 0.5vw;
                        height: 3vw;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        line-height: 1.2vw;
                        font-size: 1vw;
    
                        @include sm{
                            height: 7vw;

                        }
                        
                        span{
                            width: 11vw;
                            max-height: 2.5vw;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;  
                            line-height: 1.2vw;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: pre-line;
                            
                            @include sm{
                                width: 25vw;
                                max-height: 6vw;
                                line-height: 3vw;
                                font-size: 9px;
                            }
                        }

                        .textSke{
                            width: 100%;
                            height: 15px;
                            border-radius: 10px;
                            margin-top: 5px;

                            @include sm{
                                height: 7px;
                                margin-top: 3px;
                            }
                        }
                    }
                }
            }
        }

        .pageControl{
            width: 100%;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 30px;

            .currentPage{
                position: relative;
                width: 40px;
                height: 25px;
                background-color: #ffffff;
                border: 1px solid #e7e5e5;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                user-select: none;


                .pageList{
                    width: 38px;
                    height: 75px;
                    position: absolute;
                    background-color: #ffffff;
                    border: 1px solid rgb(153, 153, 153);
                    left: 0;
                    // transform: translateX(-50%);
                    top:-78px;
                    overflow-y: scroll;

                    &::-webkit-scrollbar {
                        width: 5px;
                        
                    }
                    &::-webkit-scrollbar-track {
                        background: #d3d3d3;
                        border-radius: 120px;
                    }
                    &::-webkit-scrollbar-thumb { 
                        background: #1b08eb;
                        width: 4px;
                        height: 6px; 
                        border-radius: 120px;
                    }

                    .pageNumber{
                        display: flex;
                        height: 25px;
                        justify-content: center;
                        border-bottom: 1px solid #bfbfbf;
                        cursor: pointer;
                    }

                    .pageNumberSelected{
                        background-color: #4b6bd2;
                        color: white;
                        display: flex;
                        height: 25px;
                        justify-content: center;
                        border-bottom: 1px solid #bfbfbf;
                        // cursor: pointer;
                    }
                }
            }

            .arrow{
                width: 20px;
                height: 25px;
                background-color: #e7e5e5;
            }


            .pageAction{
                padding: 0 10px;
                width: 100px;
                height: 25px;
                background-color: #4841fd;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                border-radius: 5px;
                margin: 10px;
                cursor: pointer;
                padding-bottom: 2px;
            }

            .pageActionDisabled{
                padding: 0 10px;
                width: 100px;
                height: 25px;
                background-color: #202020;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                border-radius: 5px;
                margin: 10px;
                padding-bottom: 2px;
            }
        }
    }
    .widgetWrapper{
        width: 30%;

        @include sm{
            display: none;
        }
    }
}