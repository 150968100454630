@mixin sm {
    @media only screen and (max-width: 600px) {
      @content;
    }
}


.container{
    margin-top: 70px;
    width: 80vw;
    margin-left: 10%;
    height: 100vh;

    @include sm{
        width: 94vw;
        margin-left: 3%;
    }

    .title{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;

        span{
            font-size: 40px;
            font-weight: 600;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            color: #2B65BC;

            @include sm{
                text-align: center;
                font-size: 30px;
            }
        }
    }

    .body{
        width: 100%;
        box-sizing: border-box;
        padding: 20px 50px 60px 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;

        @include sm{
            padding: 15px 10px;
            display: block;
        }

        .text{
            flex: 1;

            h3{
                margin: 10px;

                @include sm{
                    margin: 10px 0;
                }
            }
            .bullet{
                display: flex;
                align-items: center;
                margin-left: 15px;

                @include sm{
                    max-width: 100%;
                    margin-left: 0;
                    padding-left: 15px;
                    box-sizing: border-box;
                    font-size: 14px;
                }
                .dot{
                    width: 10px;
                    height: 10px;
                    margin-right: 10px;
                    margin-top: 4px;
                }
            }

            .bulletLink{
                display: flex;
                align-items: center;
                margin-left: 15px;

                @include sm{
                    max-width: 100%;
                    margin-left: 0;
                    padding-left: 15px;
                    box-sizing: border-box;
                    font-size: 14px;
                    word-break: break-all;
                }
                .dot{
                    width: 10px;
                    height: 10px;
                    margin-right: 10px;
                    margin-top: 4px;

                }
                
                a{
                    color: rgb(235, 3, 3);
                    font-size: 1.1em;
                }
            }

            .bulletNote{
                display: flex;
                align-items: center;
                margin-left: 15px;
                color: rgb(25, 0, 255);

                @include sm{
                    max-width: 100%;
                    margin-left: 0;
                    padding-left: 15px;
                    box-sizing: border-box;
                    font-size: 14px;
                }
                .dot{
                    width: 10px;
                    height: 10px;
                    margin-right: 10px;
                    margin-top: 4px;
                    color: black;
                }
            }

            .text1{

                @include sm{
                    width: 100%;
                    margin-bottom: 30px;
                }
            }


            .main{

                @include sm{

                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .mobileImg{
                    display: none;

                    @include sm{
                        
                        
                        width: 150px;
                        height: 100%;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    img{
                        width: 150px;
                        height: 150px;
                    }
                }

                .text2{
                    

                    @include sm{
                        flex: 1;
                    }
                }
            }
            
        }

        .img{
            width: 20vw;
            height: 100%;
            display: flex;
            align-items: center;

            @include sm{
                display: none;
            }
            img{
                width: 20vw;
                height: 20vw;
            }


        }
    }

    .contact{
        margin-top: 20px;
        width: 100%;
        height: fit-content;
        background-color: #fff;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;


        .text{
           
            width: 100%;
            height: 3vw;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 5px;
            font-size: 1.3vw;
            font-weight: 600;

            @include sm{
                font-size: 18px;
                margin-bottom: 15px;
                margin-top: 15px;
            }
        }

        .contactInfo{
            width: 60%;
            height: fit-content;
            background-color: #E8EBF6;
            box-sizing: border-box;
            padding: 17px 25px;
            display: flex;      
            justify-content: center;
            border-radius: 20px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            cursor: pointer;
            margin-bottom: 20px;

            @include sm{
                width: 80%;

            }
            

            a{
                font-size: 15px;
                text-align: center;
                font-weight: 700;
            }
        }
    }
}